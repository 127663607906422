import React from "react"
import "../styles/contact.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { motion } from "framer-motion"
import { Link } from "gatsby"
import {
    containerFast,
    fadeDown,
    fadeUp,
    container,
    scaleUp,
} from "../animations"

const IndexPage = () => (
    <Layout className="contact">
        <SEO title="Contact Us" />
        <motion.div variants={container} initial="out" animate="in">
            <motion.h1 variants={fadeDown}>Contact Us!</motion.h1>
            <div className="phone-contact">
                <motion.div variants={fadeDown}>
                    <img alt="Phone" src="https://img.icons8.com/doodle/48/000000/ringer-volume--v1.png"/>
                    <motion.p className="by-phone">Via phone: <a href="tel:5195992337">519.599.2337</a></motion.p>
                </motion.div>
                <motion.div variants={fadeDown}>
                    <img alt="Text" src="https://img.icons8.com/doodle/48/000000/sms.png"/>
                    <motion.p className="by-phone">Via text: <a href="tel:2262155816">226.215.5816</a></motion.p>
                </motion.div>
            </div>
            <motion.form variants={containerFast} initial="out" animate="in" action="https://formsubmit.co/info@birchandbenjamin.com" method="POST">
                <motion.input variants={fadeDown} type="email" name="email" placeholder="Email Address" />
                <motion.input variants={fadeDown} type="hidden" name="_next" value="https://birch-and-benjamin-staging.herokuapp.com/thanks" />
                <motion.input variants={fadeDown} type="text" name="_subject" placeholder="Subject" />
                <motion.input variants={fadeDown} type="text" name="name" placeholder="Full Name" />
                <motion.input variants={fadeDown} type="text" name="_honey" style={{display: "none"}} />
                <motion.textarea variants={fadeDown} placeholder="Your Message" class="form-control" name="message" rows="10" required />
                <motion.input variants={fadeDown} className="pure-button" type="submit"/>
            </motion.form>
        </motion.div>
        <motion.nav
            variants={container}
            initial="out"
            animate="in"
            className="nav-row"
        >
            <motion.div variants={fadeUp} whileHover={scaleUp}>
                <Link to={"/"}>Go Back</Link>
            </motion.div>
        </motion.nav>
    </Layout>
)

export default IndexPage
